import { Box, Button, ButtonGroup, Card, Checkbox, FormControlLabel, Grid, TextField, Typography } from '@mui/material';
import { useEffect, useState } from 'react';
import Scrollbars from 'react-custom-scrollbars-2';
import { useDispatch, useSelector } from 'react-redux';
import { PageHeader } from '../../../app/AppStyles';
import { getPrograms, postProgram, putProgram, setFailedProgram } from './programManagementSlice';

const modalStyle = {
    position: 'absolute',
    top: '50%',
    left: '50%',
    transform: 'translate(-50%, -50%)',
    display: 'flex',
    width: { xs: '90%', md: '40%' },
    maxHeight: '90%',
    boxShadow: 24,
};

export default function ProgramEditCard({ handleClose, addNew }) {
    const dispatch = useDispatch();

    const { user } = useSelector((state) => state.user);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);
    const { selectedProgram, postProgramStatus, putProgramStatus, showProgramFailed } = useSelector((state) => state.management.program);

    // Put status callback
    useEffect(() => {
        if (putProgramStatus === "success") {
            dispatch(setFailedProgram(false));
            dispatch(getPrograms());
            handleClose();
        } else if (putProgramStatus === "failed") {
            dispatch(setFailedProgram(true));
        };
    }, [dispatch, handleClose, putProgramStatus]);

    // Post status callback
    useEffect(() => {
        if (postProgramStatus === "success") {
            setFailedProgram(false);
            dispatch(getPrograms());
            handleClose();
        } else if (postProgramStatus === "failed") {
            setFailedProgram(true);
        };
    }, [dispatch, handleClose, postProgramStatus]);

    // Character limits
    const nameLength = 256;

    // Property states
    const [newProgram, setNewProgram] = useState({});
    const [errorName, setErrorName] = useState(null);
    const [changed, setChanged] = useState(false);

    // Selected program state update 
    useEffect(() => {
        if (!addNew && selectedProgram)
            setNewProgram(selectedProgram);
    }, [selectedProgram, addNew]);

    // Keep track of changed state 
    useEffect(() => {
        if (newProgram !== selectedProgram) {
            setChanged(true);
        } else {
            setChanged(false);
        };
    }, [selectedProgram, newProgram]);

    // Add click function (check property character limits etc.)
    const addClick = () => {
        if (!newProgram.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newProgram.name && newProgram.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        newProgram.CreatedBy = `AP/${user.number}`;

        dispatch(postProgram(newProgram));
    };

    // Update click function (check property character limits etc.)
    const updateClick = () => {
        if (!newProgram.name) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_PROPERTY_EMPTY}`);
        setErrorName(null);

        if (newProgram.name && newProgram.name?.length > nameLength) return setErrorName(`${resourceFinal[appLanguage].messages.ERROR_TOO_LONG} ${nameLength}`);
        setErrorName(null);

        newProgram.ModifiedBy = `AP/${user.number}`;

        dispatch(putProgram(newProgram));
    };

    return (
        <Box sx={{ ...modalStyle }}>
            <Card sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto', p: 2 }}>
                <Scrollbars autoHeight autoHeightMin="100%" autoHeightMax="100%" autoHide autoHideTimeout={1000} autoHideDuration={200}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <Typography variant="h6" sx={{ ...PageHeader }}>{resourceFinal[appLanguage].words.PROGRAM} {addNew ? resourceFinal[appLanguage].words.ADD.toLowerCase() : resourceFinal[appLanguage].words.EDIT.toLowerCase()}</Typography>
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth required
                                defaultValue={selectedProgram?.name}
                                label={resourceFinal[appLanguage].words.NAME}
                                onChange={(event) => setNewProgram({
                                    ...newProgram,
                                    name: event.target.value
                                })}
                                error={errorName !== null}
                                helperText={errorName}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <TextField
                                fullWidth multiline
                                defaultValue={selectedProgram?.description}
                                label={resourceFinal[appLanguage].words.DESCRIPTION}
                                onChange={(event) => setNewProgram({
                                    ...newProgram,
                                    description: event.target.value
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={4}>
                            <FormControlLabel
                                label={`${resourceFinal[appLanguage].words.FAVORITES} ${resourceFinal[appLanguage].words.REQUIRED.toLowerCase()}`}
                                control={<Checkbox
                                    checked={newProgram?.favoritesRequired ? newProgram?.favoritesRequired : false}
                                    onChange={(event) => setNewProgram({
                                        ...newProgram,
                                        favoritesRequired: event.target.checked ? true : false
                                    })}
                                />}
                            />
                        </Grid>
                        <Grid item xs={8}>
                            <TextField
                                fullWidth type="number"
                                disabled={!newProgram?.favoritesRequired}
                                inputProps={{ min: 0 }}
                                defaultValue={selectedProgram?.favoritesMinimum ?? 0}
                                label={`${resourceFinal[appLanguage].words.FAVORITES} ${resourceFinal[appLanguage].words.MINIMUM.toLowerCase()}`}
                                onChange={(event) => setNewProgram({
                                    ...newProgram,
                                    favoritesMinimum: event.target.value
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: siomSettings?.flagProgress ? 'flex' : 'none' }}>
                            <TextField
                                fullWidth type="number"
                                disabled={!siomSettings?.flagProgress}
                                inputProps={{ min: 0 }}
                                defaultValue={selectedProgram?.greenFlagsNecessary}
                                label={`${resourceFinal[appLanguage].words.NECESSARY} ${resourceFinal[appLanguage].words.FLAGS_GREEN_MULT.toLowerCase()}`}
                                onChange={(event) => setNewProgram({
                                    ...newProgram,
                                    greenFlagsNecessary: event.target.value
                                })}
                                variant="outlined"
                            />
                        </Grid>
                        <Grid item xs={12}>
                            {showProgramFailed && <>
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_SAVE_FAILED}</Typography>
                                <Typography variant="body1" color="error" sx={{ textAlign: 'center' }}>{resourceFinal[appLanguage].messages.ERROR_DUPLICATE_NAME}</Typography>
                            </>}
                        </Grid>
                        <Grid item xs={12} sx={{ display: 'flex', justifyContent: 'center' }}>
                            <ButtonGroup sx={{ justifyContent: 'center' }}>
                                <Button variant="outlined" color="error" onClick={handleClose}>{resourceFinal[appLanguage].words.CANCEL}</Button>
                                {!addNew && <Button disabled={!changed} variant="outlined" color="primary" onClick={updateClick}>{resourceFinal[appLanguage].words.SAVE}</Button>}
                                {addNew && <Button variant="outlined" color="primary" onClick={addClick}>{resourceFinal[appLanguage].words.ADD}</Button>}
                            </ButtonGroup>
                        </Grid>
                    </Grid>
                </Scrollbars>
            </Card>
        </Box>
    );
}
