import AddIcon from '@mui/icons-material/Add';
import ArrowForwardIosIcon from '@mui/icons-material/ArrowForwardIos';
import DeleteIcon from '@mui/icons-material/Delete';
import EditIcon from '@mui/icons-material/Edit';
import { Box, Button, Grid, IconButton, Modal, Stack, Table, TableBody, TableCell, TableContainer, TableFooter, TableHead, TablePagination, TableRow, Tooltip, Typography, alpha } from '@mui/material';
import { useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from "react-router-dom";
import { MainBorderRadius, PageHeader, TableHeader } from '../../../app/AppStyles';
import { GetDate, GetTime } from '../../../app/AppUtils';
import BackButton from '../../subcomponents/BackButton';
import ConfirmationModal from '../../subcomponents/ConfirmationModal';
import ContentLoader from '../../subcomponents/ContentLoader';
import ListItemCard from '../../subcomponents/ListItemCard';
import PageLayout from '../../subcomponents/PageLayout';
import TablePaginationActions from '../../subcomponents/TablePaginationActions';
import { setAlertMessage, setAlertSeverity, setAlertStatus } from '../../subcomponents/alertSlice';
import { selectSubprogram } from '../subprogram/subprogramManagementSlice';
import ProgramSubprogramAddCard from './ProgramSubprogramAddCard';
import ProgramSubprogramEditCard from './ProgramSubprogramEditCard';
import { deleteProgramSubprograms, getProgramSubprograms, resetProgramSubprogramsStatus, selectProgramSubprogram, setFailedProgramSubprograms } from './programManagementSlice';

export default function ProgramPage() {
    const dispatch = useDispatch();
    const navigate = useNavigate();

    const [openEdit, setOpenEdit] = useState(false);
    const [openNew, setOpenNew] = useState(false);

    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { selectedProgram, programSubprograms, programSubprogramsStatus, deleteProgramSubprogramsStatus } = useSelector((state) => state.management.program);

    useEffect(() => {
        if (selectedProgram)
            dispatch(getProgramSubprograms(selectedProgram.id));
        else
            navigate("/programmabeheer")
    }, [dispatch, navigate, selectedProgram])

    useEffect(() => {
        if (deleteProgramSubprogramsStatus === "success") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.DELETE_SUCCESS}`));
            dispatch(setAlertSeverity("success"));
            dispatch(resetProgramSubprogramsStatus());
            dispatch(getProgramSubprograms(selectedProgram.id));
        } else if (deleteProgramSubprogramsStatus === "failed") {
            dispatch(setAlertStatus(true));
            dispatch(setAlertMessage(`${resourceFinal[appLanguage].messages.ERROR_DELETE_FAILED}`));
            dispatch(setAlertSeverity("error"));
            dispatch(resetProgramSubprogramsStatus());
        };
    }, [dispatch, resourceFinal, appLanguage, selectedProgram, deleteProgramSubprogramsStatus]);

    const newClick = () => {
        dispatch(selectProgramSubprogram(null));
        dispatch(setFailedProgramSubprograms(false));
        setOpenNew(true);
    };

    const callbackModalNew = () => {
        setOpenNew(false);
        dispatch(selectProgramSubprogram(null));
        dispatch(resetProgramSubprogramsStatus());
        dispatch(setFailedProgramSubprograms(false));
    };

    const callbackModalEdit = () => {
        setOpenEdit(false);
        dispatch(selectProgramSubprogram(null));
        dispatch(resetProgramSubprogramsStatus());
        dispatch(setFailedProgramSubprograms(false));
    };

    const TableFragment = () => {
        const [page, setPage] = useState(0);
        const [rowsPerPage, setRowsPerPage] = useState(10);

        const emptyRows = page > 0 ? Math.max(0, (1 + page) * rowsPerPage - programSubprograms?.length) : 0;

        const handleChangePage = (event, newPage) => {
            setPage(newPage);
        };

        const handleChangeRowsPerPage = (event) => {
            setRowsPerPage(parseInt(event.target.value, 10));
            setPage(0);
        };

        const editClick = (programSubprogram) => {
            dispatch(selectProgramSubprogram(programSubprogram));
            setOpenEdit(true);
        };

        const deleteClick = (programId) => {
            dispatch(deleteProgramSubprograms([programId]));
        };

        const selectClick = (subprogram) => {
            dispatch(selectSubprogram(subprogram));
            navigate(`/programmabeheer/deelprogramma/${subprogram.id}`);
        };

        return (
            <>
                <TableBody>
                    {(rowsPerPage > 0 ? programSubprograms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : programSubprograms)?.map(item =>
                        <TableRow key={item.id} sx={{ '&:nth-of-type(odd)': { bgcolor: 'white.main' }, height: 63 }}>
                            <TableCell sx={{ cursor: 'pointer' }} onClick={() => selectClick(item.subprogram)} align="left">{item.subprogram.name ? item.subprogram.name : "-"}</TableCell>
                            <Tooltip title={item.subprogram.description ? item.subprogram.description : "-"}>
                                <TableCell align="left" sx={{
                                    whiteSpace: "nowrap",
                                    maxWidth: "200px",
                                    width: "100px",
                                    overflow: "hidden",
                                    textOverflow: "ellipsis"
                                }}>{item.description ?? "-"}</TableCell>
                            </Tooltip>
                            <TableCell align="center">{item.minPoints ?? "-"}</TableCell>
                            <TableCell align="center">{item.maxPoints ?? "-"}</TableCell>
                            <TableCell align="center">{item.maxAbsence ?? "-"}</TableCell>
                            <TableCell align="center">{GetDate(item.startTime)} {GetTime(item.startTime)}</TableCell>
                            <TableCell align="center">{GetDate(item.endTime)} {GetTime(item.endTime)}</TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => editClick(item)}><EditIcon color="secondary" /></IconButton></TableCell>
                            <TableCell align="center" padding="checkbox">
                                <ConfirmationModal iconButton={true} icon={<DeleteIcon color="secondary" />} message={`${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_1} ${item.subprogram.name} ${resourceFinal[appLanguage].messages.DELETE_CONFIRMATION_2}`} confirmAction={() => deleteClick(item.id)} />
                            </TableCell>
                            <TableCell align="center" padding="checkbox"><IconButton onClick={() => selectClick(item.subprogram)}><ArrowForwardIosIcon color="secondary" /></IconButton></TableCell>
                        </TableRow>
                    )}
                    {(rowsPerPage > 0 ? programSubprograms.slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage) : programSubprograms)?.length === 0 && <TableRow><TableCell colSpan={13}>{resourceFinal[appLanguage].messages.FILTER_RESULT_EMPTY}</TableCell></TableRow>}
                    {emptyRows > 0 && <TableRow style={{ height: 63 * emptyRows }}><TableCell colSpan={13} /></TableRow>}
                </TableBody>
                <TableFooter>
                    <TableRow>
                        <TablePagination
                            sx={{ border: 0 }}
                            rowsPerPageOptions={[5, 10, 20, { label: resourceFinal[appLanguage].words.ALL, value: -1 }]}
                            count={programSubprograms?.length}
                            rowsPerPage={rowsPerPage}
                            page={page}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                            ActionsComponent={TablePaginationActions}
                            labelDisplayedRows={({ from, to, count }) => { return `${from}-${to} ${resourceFinal[appLanguage].messages.OF_THE} ${count}`; }}
                            labelRowsPerPage={resourceFinal[appLanguage].messages.TABLE_ROWS_PER_PAGE}
                        />
                    </TableRow>
                </TableFooter>
            </>
        );
    };

    return (
        <>
            <PageLayout content={
                <Stack spacing={1} sx={{ flex: '1 1 auto', bgcolor: 'white.light', borderRadius: MainBorderRadius, p: 0.5 }}>
                    <Grid container spacing={1}>
                        <Grid item xs={12}>
                            <ListItemCard altBgColor={{ bgcolor: theme => alpha(theme.palette.primary.light, 0.3) }}
                                primaryText={selectedProgram?.name}
                                secondaryText={resourceFinal[appLanguage].words.PROGRAM}
                            />
                        </Grid>
                        <Grid item xs={6} sx={{ display: 'none' }}>
                            <ListItemCard altBgColor={{ bgcolor: theme => alpha(theme.palette.primary.light, 0.3) }}
                                primaryText={selectedProgram?.greenFlagsNecessary ?? "-"}
                                secondaryText={`${resourceFinal[appLanguage].words.NECESSARY} ${resourceFinal[appLanguage].words.FLAGS_GREEN_MULT.toLowerCase()}`}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <ListItemCard altBgColor={{ bgcolor: theme => alpha(theme.palette.primary.light, 0.3) }}
                                primaryText={selectedProgram?.description}
                                secondaryText={resourceFinal[appLanguage].words.DESCRIPTION}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ListItemCard altBgColor={{ bgcolor: theme => alpha(theme.palette.primary.light, 0.3) }}
                                primaryText={selectedProgram?.favoritesRequired ? resourceFinal[appLanguage].words.YES : resourceFinal[appLanguage].words.NO}
                                secondaryText={`${resourceFinal[appLanguage].words.FAVORITES} ${resourceFinal[appLanguage].words.REQUIRED.toLowerCase()}`}
                            />
                        </Grid>
                        <Grid item xs={6}>
                            <ListItemCard altBgColor={{ bgcolor: theme => alpha(theme.palette.primary.light, 0.3) }}
                                primaryText={selectedProgram?.favoritesMinimum}
                                secondaryText={`${resourceFinal[appLanguage].words.FAVORITES} ${resourceFinal[appLanguage].words.MINIMUM.toLowerCase()}`}
                            />
                        </Grid>
                    </Grid>
                    <ContentLoader successCondition={programSubprogramsStatus === "success"} errorCondition={programSubprogramsStatus === "failed"} content={
                        <Box sx={{ display: 'flex', flexDirection: 'column', flex: '1 1 auto' }}>
                            <Box sx={{ display: 'flex', justifyContent: 'space-between', bgcolor: theme => alpha(theme.palette.primary.light, 0.3), borderRadius: MainBorderRadius, p: 1 }}>
                                <Typography variant="h6" sx={{ ...PageHeader, pb: 0 }}>{resourceFinal[appLanguage].words.LINKED2} {resourceFinal[appLanguage].words.SUBPROGRAMS.toLowerCase()}</Typography>
                                <Button variant="outlined" onClick={newClick} startIcon={<AddIcon />}>{resourceFinal[appLanguage].words.ADD}</Button>
                            </Box>
                            <TableContainer>
                                <Table size="small">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.NAME}</TableCell>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS_MIN}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS_MAX}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.ABSENCE_MAX}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.START}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.END}{resourceFinal[appLanguage].words.TIME.toLowerCase()}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}></TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableFragment />
                                </Table>
                            </TableContainer>
                        </Box>
                    } />
                </ Stack>
            } contentRight={
                <Box sx={{ display: 'flex', flex: '1 1 auto', justifyContent: 'flex-end' }}>
                    <BackButton desktop />
                </Box>
            } />

            <Modal open={openEdit} onClose={callbackModalEdit}>
                <>
                    <ProgramSubprogramEditCard handleClose={callbackModalEdit} />
                </>
            </Modal>

            <Modal open={openNew} onClose={callbackModalNew}>
                <>
                    <ProgramSubprogramAddCard handleClose={callbackModalNew} />
                </>
            </Modal>
        </>
    );
}
