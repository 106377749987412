import { Card, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Typography } from '@mui/material';
import Grid from '@mui/material/Unstable_Grid2/Grid2';
import { useSelector } from 'react-redux';
import { CardHeader, CardMedium, SubHeader, TableHeader } from '../../app/AppStyles';
import { GetDate } from '../../app/AppUtils';
import GridCard from '../subcomponents/GridCard';

export default function ProgressProgramDisplay({ progressProgram }) {
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);

    return (
        <Card elevation={0} sx={{ ...CardMedium }}>
            <Grid container>
                <Grid xs={12}>
                    <Typography variant="h6" sx={{ ...CardHeader }} gutterBottom>{resourceFinal[appLanguage].words.PROGRAM}</Typography>
                </Grid>
                <Grid xs={12}>
                    <Typography variant="body1" display="inline" sx={{ ...SubHeader }}>{resourceFinal[appLanguage].words.NAME}: </Typography>
                    <Typography variant="body1" display="inline">{progressProgram?.programName}</Typography>
                </Grid>
                {!!progressProgram?.programDescription &&
                    <Grid xs={12}>
                        <Typography variant="body1" display="inline" sx={{ ...SubHeader }}>{resourceFinal[appLanguage].words.DESCRIPTION}: </Typography>
                        <Typography variant="body1" display="inline">{progressProgram?.programDescription}</Typography>
                    </Grid>
                }
                {progressProgram?.subPrograms.length > 0 &&
                    <Grid xs={12}>
                        <>
                            <TableContainer sx={{ display: { xs: 'none', md: 'flex' } }}>
                                <Table sx={{ minWidth: 650 }} aria-label="simple table">
                                    <TableHead>
                                        <TableRow>
                                            <TableCell align="left" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.SUBPROGRAMS}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PROGRAM_START}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.PROGRAM_END}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS_MIN}</TableCell>
                                            <TableCell align="center" sx={{ ...TableHeader }}>{resourceFinal[appLanguage].words.POINTS_MAX}</TableCell>
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {progressProgram?.subPrograms.map((item) => (
                                            <TableRow key={item.programName} sx={{ '&:last-child td, &:last-child th': { border: 0 } }}>
                                                <TableCell align="left">{item.programName}</TableCell>
                                                <TableCell align="center">{GetDate(item.programStart)}</TableCell>
                                                <TableCell align="center">{GetDate(item.programEnd)}</TableCell>
                                                <TableCell align="center">{item.programMinPoints}</TableCell>
                                                <TableCell align="center">{item.programMaxPoints}</TableCell>
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TableContainer>
                        </>
                        <>
                            <Grid container spacing={0.5} sx={{ display: { xs: 'flex', md: 'none' }, mt: 1 }}>
                                {progressProgram?.subPrograms.map((item, index) =>
                                    <Grid xs={12} key={index}>
                                        <GridCard
                                            textPrimary={item.programName}
                                            textPrimaryStyle={{ fontWeight: 'bold', fontSize: 16 }}
                                            textSecondary={<><strong>{resourceFinal[appLanguage].words.DATE}:</strong><> {GetDate(item.programStart)} - {GetDate(item.programEnd)}</></>}
                                            textSecondaryStyle={{ fontSize: 14 }}
                                            textTertiary={<><strong>{resourceFinal[appLanguage].words.POINTS_MIN}:</strong><> {item.programMinPoints}</></>}
                                            textTertiaryStyle={{ fontSize: 14 }}
                                            textQuaternary={<><strong>{resourceFinal[appLanguage].words.POINTS_MAX}:</strong><> {item.programMaxPoints}</></>}
                                            textQuaternaryStyle={{ fontSize: 14 }}
                                            tooltipEnabled
                                        />
                                    </Grid>
                                )}
                            </Grid>
                        </>
                    </Grid>
                }
            </Grid>
        </Card >
    );
}

