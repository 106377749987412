import MenuIcon from '@mui/icons-material/Menu';
import { AppBar, Box, Button, IconButton, Menu, Toolbar, useMediaQuery } from '@mui/material';
import { useState } from 'react';
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import DepartmentPicker from '../department/DepartmentPicker';
import IconDisplay from '../subcomponents/IconDisplay';
import NavMenuItem from '../subcomponents/NavMenuItem';
import LoginMenu from './LoginMenu';

export default function NavMenu() {
    const navigate = useNavigate();
    const [anchorElNav, setAnchorElNav] = useState(null);
    const { activeThemeSettings } = useSelector((state) => state.settings.theme);
    const { navigation } = useSelector((state) => state.settings.resource.navigation);
    const { appLanguage, resourceFinal } = useSelector((state) => state.settings.resource.resources);
    const { siomSettings } = useSelector((state) => state.settings.siom);

    const { student } = useSelector((state) => state.student);

    const handleOpenNavMenu = (event) => {
        setAnchorElNav(event.currentTarget);
    };

    const handleCloseNavMenu = () => {
        setAnchorElNav(null);
    };

    const handleRedirect = (path) => {
        let page = "/";

        if (path === `/${resourceFinal[appLanguage].words.SCHEDULE}`) page = '/agenda';
        if (path === `/${resourceFinal[appLanguage].words.REGISTER}`) page = '/inschrijven';
        if (path === `/${resourceFinal[appLanguage].words.PROGRESS}`) page = '/voortgang';
        if (path === `/${resourceFinal[appLanguage].words.CONTACT}`) page = '/contact';
        if (path === `/${resourceFinal[appLanguage].words.NEWS}`) page = '/nieuws';
        if (path === `/${resourceFinal[appLanguage].words.FAVORITES}`) page = '/favorieten';
        if (path === `/${resourceFinal[appLanguage].words.INFORMATION}`) page = '/informatie';
        if (path === `/${resourceFinal[appLanguage].words.TIMETABLE}`) page = '/rooster';
        if (path === `/${resourceFinal[appLanguage].words.ACTIVITY_MANAGEMENT}`) page = '/activiteitenbeheer';
        if (path === `/${resourceFinal[appLanguage].words.PROGRAM_MANAGEMENT}`) page = '/programmabeheer';
        if (path === `/${resourceFinal[appLanguage].words.PLANNING}`) page = '/plannen';
        if (path === `/${resourceFinal[appLanguage].words.WEB_MANAGEMENT}`) page = '/webbeheer';
        if (path === `/${resourceFinal[appLanguage].words.REPORTS_PAGE}`) page = '/rapporten';
        if (path === `/${resourceFinal[appLanguage].words.ADMIN_PAGE}`) page = '/admin';
        if (path === `/${resourceFinal[appLanguage].words.HOME}`) page = '/';
        if (path === `/${resourceFinal[appLanguage].words.ABOUT}`) page = '/over';
        if (path === `/${resourceFinal[appLanguage].words.ATTENDANCE}`) page = '/presentie';
        if (path === `/${resourceFinal[appLanguage].words.SEARCH_PAGE}`) page = '/zoeken/student';
        if (path === `/${resourceFinal[appLanguage].words.MESSAGES_PAGE}`) page = '/berichten';
        if (path === `/${resourceFinal[appLanguage].words.EMPLOYEE_PAGE}`) page = '/medewerkers';
        if (path === `/${resourceFinal[appLanguage].words.TRAVEL_COSTS}`) page = '/reiskosten';

        navigate(page);
    };

    const mobileBgImage = { backgroundImage: theme => `linear-gradient(110deg, ${theme.palette.secondary?.main} 50%, ${theme.palette.primary?.main} 50%)` }
    const bgImage = useMediaQuery('(min-width:900px)') ? '' : mobileBgImage;

    return (
        <AppBar position="static" sx={{ ...bgImage }}>
            <Toolbar disableGutters>
                {/* MD LOGO */}
                <Box onClick={() => handleRedirect("/")} sx={{
                    display: { xs: 'none', md: 'flex' }, justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer', px: 4, minWidth: 200,
                    backgroundImage: theme => `linear-gradient(110deg, ${theme.palette.secondary?.main} 10%, ${theme.palette.white?.light} 10.5%, ${theme.palette.white?.light} 89.5%, ${theme.palette.primary?.main} 90%)`
                }}>
                    <img src={activeThemeSettings?.logo} alt="logo" style={{ maxWidth: 240, maxHeight: 60, objectFit: 'scale-down' }} />
                </Box>

                {/* MD MENU */}
                <Box sx={{ flexGrow: 1, display: { xs: 'none', md: 'flex' } }}>
                    {navigation.map((item) => (
                        <Button
                            key={item}
                            onClick={() => {
                                handleCloseNavMenu()
                                handleRedirect(`/${item}`)
                            }}
                            sx={{ display: 'block', fontWeight: 'bold', color: 'white.main' }}
                        >
                            {item}
                        </Button>
                    ))}
                </Box>

                {siomSettings.useMultipleDepartments && student !== null ? <Box sx={{ display: { xs: 'none', md: 'flex' } }}><DepartmentPicker type={"compact"} /></Box> : ""}

                {/* XS MENU */}
                <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
                    <IconButton
                        size="large"
                        aria-label="account of current user"
                        aria-controls="menu-appbar"
                        aria-haspopup="true"
                        onClick={handleOpenNavMenu}
                    >
                        <MenuIcon fontSize="large" sx={{ color: 'white.main' }} />
                    </IconButton>
                    <Menu
                        id="menu-appbar"
                        anchorEl={anchorElNav}
                        anchorOrigin={{
                            vertical: 'bottom',
                            horizontal: 'left',
                        }}
                        keepMounted
                        transformOrigin={{
                            vertical: 'top',
                            horizontal: 'left',
                        }}
                        open={Boolean(anchorElNav)}
                        onClose={handleCloseNavMenu}
                        sx={{
                            display: { xs: 'block', md: 'none' },
                        }}
                    >
                        {navigation.map((item) => (
                            <NavMenuItem key={item} icon={<IconDisplay component={item} style={{ color: 'secondary.main' }} />} clickFn={() => {
                                handleCloseNavMenu()
                                handleRedirect(`/${item}`)
                            }} text={item} textStyle={{ color: 'secondary.light' }} />
                        ))}
                    </Menu>
                </Box>

                {/* XS LOGO */}
                <Box onClick={() => handleRedirect("/")} sx={{
                    display: { xs: 'flex', md: 'none' }, justifyContent: 'center', alignItems: 'center', height: '100%', cursor: 'pointer', minWidth: 160, px: 4, mx: 'auto',
                    backgroundImage: theme => `linear-gradient(110deg, ${theme.palette.secondary?.main} 10%, ${theme.palette.white?.light} 10.5%, ${theme.palette.white?.light} 89.5%, ${theme.palette.primary?.main} 90%)`
                }}>
                    <img src={activeThemeSettings?.logo} alt="logo" style={{ maxWidth: 168, maxHeight: 56, objectFit: 'scale-down' }} />
                </Box>

                {/* LOGIN */}
                <Box sx={{ display: 'flex', mr: 2 }}>
                    <LoginMenu />
                </Box>
            </Toolbar>
        </AppBar>
    );
};
