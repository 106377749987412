import { combineReducers, configureStore } from "@reduxjs/toolkit";
import activityReducer from "../components/activity/activitySlice";
import adminReducer from "../components/admin/adminSlice";
import attendanceReducer from "../components/attendance/attendanceSlice";
import contactsReducer from "../components/contact/contactSlice";
import employeeReducer from "../components/employee/employeeSlice";
import favoritesReducer from "../components/favorites/favoritesSlice";
import informationReducer from "../components/information/informationSlice";
import leaderboardReducer from "../components/leaderboard/leaderboardSlice";
import activityManagementReducer from "../components/management-activity/activity/activityManagementSlice";
import locationManagementReducer from "../components/management-activity/location/locationManagementSlice";
import typeManagementReducer from "../components/management-activity/types/typeManagementSlice";
import programReducer from "../components/management-program/program/programManagementSlice";
import subprogramReducer from "../components/management-program/subprogram/subprogramManagementSlice";
import messageReducer from "../components/messages/messageSlice";
import mollieReducer from "../components/mollie/mollieSlice";
import newsReducer from "../components/news/newsSlice";
import notificationsReducer from "../components/notifications/notificationSlice";
import planningPerformanceReducer from "../components/planning/performances/planningPerformanceSlice";
import planningReducer from "../components/planning/planningSlice";
import subscriptionReducer from "../components/planning/subscriptions/subscriptionSlice";
import progressReducer from "../components/progress/progressSlice";
import registerReducer from "../components/register/registerSlice";
import reportsReducer from "../components/reports/reportsSlice";
import scheduleReducer from "../components/schedule/scheduleSlice";
import searchReducer from "../components/search/searchSlice";
import appSettingsReducer from "../components/settings/appSettingsSlice";
import resourceSettingsReducer from "../components/settings/resourceSettingsSlice";
import siomSettingsReducer from "../components/settings/siomSettingsSlice";
import studentSettingsReducer from "../components/settings/studentSettingsSlice";
import themeSettingsReducer from "../components/settings/themeSettingsSlice";
import studentReducer from "../components/student/studentSlice";
import alertReducer from "../components/subcomponents/alertSlice";
import timetableReducer from "../components/timetable/timetableSlice";
import travelCostReducer from "../components/travel/travelCostSlice";
import userReducer from "../components/user/userSlice";

const settingsReducer = combineReducers({
    app: appSettingsReducer,
    resource: resourceSettingsReducer,
    siom: siomSettingsReducer,
    student: studentSettingsReducer,
    theme: themeSettingsReducer
});

const managementReducer = combineReducers({
    activity: activityManagementReducer,
    location: locationManagementReducer,
    program: programReducer,
    subprogram: subprogramReducer,
    type: typeManagementReducer,
});

const planningComReducer = combineReducers({
    planning: planningReducer,
    performance: planningPerformanceReducer,
});

export default configureStore({
    reducer: {
        activity: activityReducer,
        admin: adminReducer,
        alert: alertReducer,
        attendance: attendanceReducer,
        contacts: contactsReducer,
        employees: employeeReducer,
        favorites: favoritesReducer,
        information: informationReducer,
        leaderboard: leaderboardReducer,
        management: managementReducer,
        messages: messageReducer,
        mollie: mollieReducer,
        news: newsReducer,
        notifications: notificationsReducer,
        planning: planningComReducer,
        progress: progressReducer,
        register: registerReducer,
        reports: reportsReducer,
        schedule: scheduleReducer,
        search: searchReducer,
        settings: settingsReducer,
        student: studentReducer,
        subscription: subscriptionReducer,
        timetable: timetableReducer,
        travelCost: travelCostReducer,
        user: userReducer
    },
});